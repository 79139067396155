import { memo, ReactNode } from 'react';
import _ from 'lodash';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface Props {
  debounceTime?: number;
  disabled?: boolean;
  endAdornment?: ReactNode;
  label: string;
  onSearch: (e: string) => void;
}

const TableSearchInput = ({
  debounceTime = 300,
  disabled = false,
  endAdornment,
  label,
  onSearch,
}: Props) => {
  const handleSearch = _.debounce((event) => {
    const searchText = event.target.value;
    onSearch(searchText);
  }, debounceTime);

  return (
    <TextField
      disabled={disabled}
      fullWidth
      placeholder={label}
      variant="outlined"
      InputProps={{
        startAdornment: <SearchIcon sx={{ color: 'secondary.main' }} />,
        inputProps: {
          sx: {
            height: '34px',
            padding: 0,
            borderColor: 'secondary.main',
          },
        },
        endAdornment,
      }}
      onChange={handleSearch}
      sx={{
        borderColor: 'secondary.main',
        height: '34px',
        width: '235px',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'secondary.main',
          },
          '&:hover fieldset': {
            borderColor: 'secondary.main',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'primary.main',
          },
        },
      }}
    />
  );
};

export default memo(TableSearchInput);
