import { memo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useCourses } from '../../../contexts/courses/context';

import LoadingComponent from '../../../components/Controls/LoadingComponent';
import SelectPaymentForm from '../SelectPaymentForm';

import { formatPrice, formatPriceBasedOnInstallments } from '../../../helpers/payments';

import classes from './classes';

interface Props {
  loading: boolean;
}

const SelectPayment = ({ loading }: Props) => {
  const {
    getAmount,
    isPreinscriptionPayment,
    getQuotes,
  } = useCourses();
  const { state } = useLocation();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(null);

  return (
    <Box sx={classes.selectPaymentWrapper}>
      <Box sx={classes.selectPaymentContainer}>
        <Typography variant="h2" fontWeight={700} color="primary.main">
          Selecciona un medio de pago
        </Typography>
        <Box my={1.25}>
          <LoadingComponent size={24} loading={loading}>
            <>
              <Typography variant="h3" display="inline" fontWeight={700}>
                {`Valor ${isPreinscriptionPayment() ? 'de preinscripción:' : 'del curso:'} `}
              </Typography>
              <Typography variant="h3" display="inline" fontWeight={700}>
                {formatPrice(getAmount())}
              </Typography>
            </>
          </LoadingComponent>
        </Box>
        {isPreinscriptionPayment()
          ? (
            <Typography variant="h4" fontWeight={500}>
              (No se descuenta del valor total del curso)
            </Typography>
          )
          : (
            <LoadingComponent size={24} loading={loading}>
              <Typography variant="h4" fontWeight={500}>
                {`A pagar en 
                  ${getQuotes() === 1 ? `${getQuotes()} cuota` : `${getQuotes()} cuotas`} 
                  de ${formatPriceBasedOnInstallments(getAmount(), getQuotes())}`}
              </Typography>
            </LoadingComponent>
          )}

        <SelectPaymentForm
          value={selectedPaymentMethod}
          setValue={setSelectedPaymentMethod}
        />

        {state?.course.preinscription && (
          <>
            <Typography variant="h5" color="text.disabled">
              Precio total del curso:
            </Typography>
            {state?.course.tariff.map((tariff) => (
              <Typography variant="h5" color="text.disabled" key={tariff.category}>
                <span style={{ fontWeight: 700 }}>
                  {`${tariff.category}: ${formatPrice(tariff.amount)} `}
                </span>
                (
                {`A pagar en ${tariff.quotes} ${tariff.quotes === 1 ? 'cuota' : 'cuotas'} de`}
                <span style={{ fontWeight: 700 }}>
                  {` ${formatPriceBasedOnInstallments(tariff.amount, tariff.quotes)}`}
                </span>
                )
              </Typography>
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default memo(SelectPayment);
