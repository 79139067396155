import { memo, useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  ExpandMore as ExpandMoreIcon,
  AccountBox as AccountBoxIcon,
} from '@mui/icons-material';
import safeParseStringIntoHTML from 'html-react-parser';

import { formatPrice, formatPriceBasedOnInstallments } from '../../helpers/payments';
import { isCourseFree } from '../../helpers/courses';
import CourseInterface, {
  PaymentInterface,
  PaymentMethod,
  TariffInterface,
  Teacher,
} from '../../interfaces/courses';

interface Props {
  course: CourseInterface
}

type AccordionItemInterface = {
  summary: 'Objetivos' | 'Programa';
  details: string;
} | {
  summary: 'Docentes';
  details: Teacher[];
} | {
  summary: 'Aranceles';
  details: {
    paymentMethods: PaymentMethod[];
    preinscription?: PaymentInterface;
    tariff: TariffInterface[];
    end_inscription_date: string;
  }
};

const buildAccordion = (course: CourseInterface): AccordionItemInterface[] => [{
  summary: 'Objetivos',
  details: course?.objectives,
}, {
  summary: 'Programa',
  details: course?.program,
}, {
  summary: 'Docentes',
  details: course?.teachers || [],
}, {
  summary: 'Aranceles',
  details: {
    paymentMethods: course?.payment_methods,
    preinscription: course?.preinscription,
    tariff: course?.tariff,
    end_inscription_date: course?.end_inscription_date,
  },
}];

const classes = {
  accordionSummaryText: {
    fontWeight: 500,
    lineHeight: '24px',
  },
};

const CourseAccordion = ({ course }: Props): JSX.Element => {
  const isFreeCourse = isCourseFree(course);

  const accordion = useMemo(() => {
    const accordionBuilded = buildAccordion(course);
    return isFreeCourse ? accordionBuilded.filter(({ summary }) => summary !== 'Aranceles') : accordionBuilded;
  }, [course, isFreeCourse]);

  return (
    <>
      {accordion.map(({ summary, details }: AccordionItemInterface) => (
        <Accordion key={summary}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${summary}-content`}
            id={`${summary}-header`}
          >
            <Typography variant="h3" sx={classes.accordionSummaryText}>
              {summary}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>

            {details && summary === 'Docentes' && (
              <Box px={2} pb={3}>
                <Typography variant="h4" color="text.disabled">
                  El cuerpo docente está conformado por:
                </Typography>
                <Box mt={3.75} display="flex">
                  {details.map((teacher) => (
                    <Box display="flex" width={263} key={teacher.name}>
                      <AccountBoxIcon sx={{ color: 'secondary.main' }} />
                      <Box display="flex" flexDirection="column" ml={1.5}>
                        <Typography variant="h4" color="text.primary" fontSize={15}>
                          {teacher.name}
                        </Typography>
                        <Typography variant="h6" color="text.disabled" fontSize={13}>
                          {teacher.category}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}

            {details && summary === 'Aranceles' && (
              <Box>
                {details.preinscription && (
                  <Box mb={3.5}>
                    <Typography variant="h4" color="primary.main" mb={1}>
                      Arancel de preinscripción:
                      {' '}
                      {formatPrice(details.preinscription.amount)}
                    </Typography>
                    <Typography variant="h5" fontSize={13}>
                      Se puede abonar la preinscripción hasta el
                      {' '}
                      {details.end_inscription_date}
                    </Typography>
                    <Typography variant="h5" fontSize={13} fontWeight={700}>
                      No se descuenta del precio total del curso.
                    </Typography>
                  </Box>
                )}
                <Typography variant="h4" color="primary.main" mb={2}>
                  Arancel del curso completo:
                </Typography>
                {details.tariff?.map((tariff) => (
                  <Box mb={1.75} key={tariff.category}>
                    <Typography variant="h5" fontSize={13} fontWeight={700} mb={0.5}>
                      {tariff.category}
                    </Typography>
                    <Typography variant="h5" fontSize={13}>
                      {tariff.quotes}
                      {' '}
                      cuotas de
                      {' '}
                      <b>
                        {formatPriceBasedOnInstallments(tariff.amount, tariff.quotes)}
                      </b>
                      {' '}
                      (
                      {formatPrice(tariff.amount)}
                      )
                    </Typography>
                  </Box>
                ))}

                <Box>
                  <Typography variant="h5" fontSize={13}>
                    Primero se deberá abonar la preinscripción para guardar la vacante
                    dentro del curso, una vez que comience la cursada se deberán ir
                    pagando mes a mes las cuotas correspondientes (a menos que desee
                    hacer un único pago al comenzar).
                  </Typography>
                </Box>
              </Box>
            )}

            {details
              && (summary !== 'Docentes' && summary !== 'Aranceles')
              && safeParseStringIntoHTML(details)}

          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default memo(CourseAccordion);
