const classes = {
  container: (center: boolean) => ({
    display: 'flex',
    ...(center && {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
    }),
  }),
};

export default classes;
