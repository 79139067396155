import { useEffect, useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Close as CloseIcon } from '@mui/icons-material';

import { useCourses } from '../../../../contexts/courses/context';
import { usePayments } from '../../../../contexts/payments/context';
import LoadingComponent from '../../../../components/Controls/LoadingComponent';
import ModalActions from './ModalActions';
import PendingPaymentsModal from '../PendingPaymentsModal';
import StudentDetails from './StudentDetails';
import { isCourseFree } from '../../../../helpers/courses';
import { QUALIFICATION_APPROVED, QUALIFICATION_PENDING } from '../../../../common/constants';
import { findQualification, qualifications } from '../constants';
import { OptionInterface } from '../../../../interfaces/inputs';
import PaymentInterface, { InstallmentStatuses } from '../../../../interfaces/payments';
import { EditQualificationModalProps } from '../types';

import classes from '../classes';

const EditQualificationModal = ({
  enrollment,
  openModal,
  onClickButton,
  onCloseModal,
}: EditQualificationModalProps) => {
  const { fetchByUserAndCourse } = usePayments();
  const { fetchCourseById } = useCourses();
  const [qualification, setQualification] = useState<string>(enrollment?.qualification || '');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPendingPaymentsModalOpen, setIsPendingPaymentsModalOpen] = useState<boolean>(false);
  const [paymentDetails, setPaymentDetails] = useState<PaymentInterface | null>(null);
  const isThereErrorInQualificationInput = useMemo(() => qualification === '' || qualification === undefined, [qualification]);

  const isQualificationPending = qualification === QUALIFICATION_PENDING;

  const fetchDetails = async () => {
    const course = await fetchCourseById(enrollment.courseId);
    if (course) {
      const isFree = isCourseFree(course);
      if (isFree) {
        setIsLoading(false);
      } else {
        const payment = await fetchByUserAndCourse(enrollment.courseId, enrollment.studentId);
        if (payment) {
          setPaymentDetails(payment);
          setIsLoading(false);
        }
      }
    }
  };

  const handleOnClick = async () => {
    setIsLoading(true);

    const newQualification = findQualification(qualification)?.id;

    if (paymentDetails) {
      const hasUnapprovedInstallments = paymentDetails.installmentsData.some(
        ({ status }) => status !== InstallmentStatuses.Approved,
      );
      const shouldApproveQualification = hasUnapprovedInstallments
        && newQualification === QUALIFICATION_APPROVED;

      if (shouldApproveQualification) {
        setIsPendingPaymentsModalOpen(true);
        setIsLoading(false);
        return;
      }
    }

    await onClickButton(newQualification);
    setIsLoading(false);
  };

  const handleConfirm = async (): Promise<void> => {
    setIsPendingPaymentsModalOpen(false);
    setIsLoading(true);

    const newQualification = findQualification(qualification)?.id;
    await onClickButton(newQualification);

    setIsLoading(false);
  };

  useEffect(() => {
    if (openModal) fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    setQualification(enrollment?.qualification);
  }, [enrollment?.qualification]);

  return (
    <>
      <Modal open={openModal} onClose={onCloseModal}>
        <Box sx={{ ...classes.modalContainer, minHeight: '400px' }}>
          <LoadingComponent center loading={isLoading} size={60}>
            <>
              <Box sx={classes.closeButtonContainer}>
                <IconButton size="large" onClick={onCloseModal} sx={classes.closeIconButton}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box sx={classes.modalContentContainer}>
                <Typography variant="h2" color="primary.main" sx={classes.modalTitle}>
                  {isQualificationPending ? 'Calificar alumno/a' : 'Editar calificación'}
                </Typography>
                <Typography variant="h5">
                  Seleccioná una calificación
                </Typography>
                <StudentDetails information={enrollment.studentName} label="Alumno/a" />
                <StudentDetails information={enrollment.document.split(' ')[1]} label="Documento" />
                <StudentDetails information={enrollment.courseEnrolled} label="Curso al que se inscribió" />
                <Autocomplete
                  disablePortal
                  value={findQualification(qualification) || ''}
                  onChange={(_e, v: OptionInterface) => setQualification(v?.name || '')}
                  options={qualifications}
                  onKeyDown={(e) => e.stopPropagation()}
                  getOptionLabel={(option: OptionInterface) => option.name || ''}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      error={isThereErrorInQualificationInput}
                      helperText={isThereErrorInQualificationInput && 'Calificación es requerida'}
                      label="Calificación"
                      variant="standard"
                    />
                  )}
                />
                <ModalActions
                  label={isQualificationPending ? 'Aceptar' : 'Guardar'}
                  loading={isLoading}
                  onCancel={onCloseModal}
                  onConfirm={handleOnClick}
                />
              </Box>
            </>
          </LoadingComponent>
        </Box>
      </Modal>
      <PendingPaymentsModal
        open={isPendingPaymentsModalOpen}
        onClick={handleConfirm}
        onCloseModal={() => setIsPendingPaymentsModalOpen(false)}
      />
    </>
  );
};

export default EditQualificationModal;
