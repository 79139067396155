import { QUALIFICATION_APPROVED, QUALIFICATION_PENDING, QUALIFICATIONS_DICTIONARY } from '../../common/constants';
import { EnrollmentInterface, RowQualificationInterface } from '../../interfaces/enrollment';

const buildRows = (rows: EnrollmentInterface[]): RowQualificationInterface[] => rows?.map(({
  _id,
  course,
  qualification,
  user,
}) => ({
  courseEnrolled: course.name,
  courseId: course.id,
  document: user.documentType.toUpperCase().concat(' ', user.document),
  editQualification: qualification !== '' && qualification !== undefined ? 'EDITAR CALIFICACIÓN' : 'CALIFICAR',
  id: _id,
  internalCourseId: course.internalCourseId,
  qualification: QUALIFICATIONS_DICTIONARY[qualification || 'pending'],
  studentId: user.id,
  studentName: user.first_name.concat(' ', user.last_name),
}));

const getColorChipForQualification = (qualification: string) => {
  if (qualification === '' || qualification === QUALIFICATIONS_DICTIONARY[QUALIFICATION_PENDING]) return 'secondary';
  if (qualification === QUALIFICATIONS_DICTIONARY[QUALIFICATION_APPROVED]) return 'success';
  return 'error';
};

const optionsFilters = [
  {
    label: 'Calificacion pendiente',
    key: 'qualification',
    value: 'pending',
  },
  {
    label: 'No finalizado',
    key: 'qualification',
    value: 'inProgress',
  },
  {
    label: 'Finalizado y no aprobado',
    key: 'qualification',
    value: 'finalized',
  },
  {
    label: 'Finalizado y aprobado',
    key: 'qualification',
    value: 'approved',
  },
];

export {
  buildRows,
  getColorChipForQualification,
  optionsFilters,
};
