import React, { useState, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  DateRange as DateRangeIcon,
  LocationOn as LocationOnIcon,
} from '@mui/icons-material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import safeParseStringIntoHTML from 'html-react-parser';

import { useAuth } from '../../contexts/auth/context';
import { useCourses } from '../../contexts/courses/context';
import { useEnrollments } from '../../contexts/enrollments/context';
import { useMessageModal } from '../../contexts/messageModal/context';
import CourseCard from '../../components/CourseCard';
import InscriptionSteps from '../../components/InscriptionSteps';
import Layout from '../../components/Layouts/Layout';
import LoadingComponent from '../../components/Controls/LoadingComponent';
import CourseAccordion from './CourseAccordion';
import { formatToValidDayjs } from '../../utils/date';
import { isCourseFree, modalityDisplayDictionary } from '../../helpers/courses';
import CourseInterface from '../../interfaces/courses';
import classes from './classes';

const Course = () => {
  const { id: idCourse } = useParams();
  const { openMessageModal } = useMessageModal();
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchActiveCourses, fetchCourseById } = useCourses();
  const { fetchEnrollments, enroll } = useEnrollments();
  const { state: { auth: user } } = useAuth();

  const [course, setCourse] = useState<CourseInterface>(null);
  const [otherCourses, setOtherCourses] = useState<CourseInterface[]>([]);
  const [tycCheckbox, setTycCheckbox] = useState<boolean>(false);
  const [hasUserInscripted, setHasUserInscripted] = useState<boolean>(false);
  const [hasUserInscriptedInWaitingList, setHasUserInscriptedInWaitingList] = useState<boolean>(
    false,
  );
  const [hasComponentLoaded, setHasComponentLoaded] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      let courseFound = location.state?.course;
      if (!courseFound) {
        setHasComponentLoaded(false);
        courseFound = await fetchCourseById(idCourse);
      }
      setCourse(courseFound);
      const enrollmentsFound = await fetchEnrollments({
        course: courseFound.id,
      });
      const enrollment = enrollmentsFound.enrollments.find(
        (e) => String(e.user) === user.id,
      );

      if (enrollment) {
        setHasUserInscripted(enrollment.lastState.type === 'enrolled');
        setHasUserInscriptedInWaitingList(enrollment.lastState.type === 'wait');
      }
      setTycCheckbox(false);
      setHasComponentLoaded(true);

      const otherCoursesFound = await fetchActiveCourses({ limit: 2, exclude: idCourse });
      setOtherCourses(otherCoursesFound);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCourse]);

  const typeModal = useMemo(() => {
    if (hasUserInscriptedInWaitingList) return 'removeWaitingList';
    if (hasUserInscripted) return 'removeEnrollment';
    return 'tyc';
  }, [hasUserInscriptedInWaitingList, hasUserInscripted]);

  const kindOfOpenModals = {
    tyc: () => openMessageModal({
      title: 'Términos y condiciones',
      message: `Antes de comenzar el curso se abonará una preinscripción, y hasta 24hs antes de la primer clase, se debe abonar la primer cuota, de no ser abonada se dará de baja la inscripción y la vacante pasará a ser liberada.
      Antes de comenzar el curso se abonará una preinscripción, y hasta 24hs antes de la primer clase, se debe abonar la primer cuota, de no ser abonada se dará de baja la inscripción y la vacante pasará a ser liberada.
      
      Antes de comenzar el curso se abonará una preinscripción, y hasta 24hs antes de la primer clase, se debe abonar la primer cuota, de no ser abonada se dará de baja la inscripción y la vacante pasará a ser liberada.`,
      variant: 'info',
      primaryButton: {
        onClick: () => {
          setTycCheckbox(true);
        },
      },
      secondaryButton: {},
      closeButton: null,
    }),
    waitingList: () => openMessageModal({
      title: 'Te anotaste en la lista de espera',
      message: 'Vas a estar recibiendo novedades en tu email',
      variant: 'success',
      primaryButton: {},
    }),
    enrollment: () => openMessageModal({
      title: 'Inscripción exitosa',
      message: 'Vas a estar recibiendo novedades del curso en tu email',
      variant: 'success',
      primaryButton: {},
    }),
    removeEnrollment: () => openMessageModal({
      title: 'Para darte de baja de este curso comunicate con IDHS',
      message: `Envia un correo electrónico a ${process.env.REACT_APP_EMAIL_IDHS} indicando el motivo de la baja del curso y seguí las indicaciones que se te brinden`,
      variant: 'infoError',
      secondaryButton: {
        text: 'Cerrar',
      },
      centerText: true,
    }),
    removeWaitingList: () => openMessageModal({
      title: '¿Estás seguro que querés darte de baja de la lista de espera?',
      variant: 'infoError',
      primaryButton: {
        text: 'Si, dar de baja',
        onClick: async () => {
          const enrollmentCanceled = await enroll(user.id, course.id, 'cancel');
          if (enrollmentCanceled) {
            setTycCheckbox(false);
            setHasUserInscripted(false);
            setHasUserInscriptedInWaitingList(false);
          }
        },
      },
      secondaryButton: {
        text: 'Volver',
      },
      centerText: true,
    }),
  };

  const goToNextStep = () => {
    navigate('./concept_user', { state: { course, tycApproved: true } });
  };

  const enrollToCourse = async () => {
    const courseEnrolled = await enroll(user.id, course.id, 'enroll');
    if (courseEnrolled) {
      kindOfOpenModals.enrollment();
      setHasUserInscripted(true);
    }
  };

  const enrollCourseToWaitingList = async () => {
    const courseEnrolled = await enroll(user.id, course.id, 'wait');
    if (courseEnrolled) {
      kindOfOpenModals.waitingList();
      setHasUserInscriptedInWaitingList(true);
    }
  };

  const areThereVacanciesFilled = useMemo(
    () => Boolean(course?.students?.length >= course?.limit_vacancies),
    [course],
  );

  const isInscriptionClosed = useMemo(
    () => (course ? dayjs().startOf('day').isSameOrAfter(formatToValidDayjs(course?.end_inscription_date)) : null),
    [course],
  );

  const isInscriptionOpen = useMemo(
    () => (course ? dayjs().startOf('day').isSameOrAfter(formatToValidDayjs(course?.start_inscription_date)) : null),
    [course],
  );

  const disableInscription = useMemo(
    () => !tycCheckbox
      || hasUserInscripted
      || isInscriptionClosed
      || !isInscriptionOpen
      || hasUserInscriptedInWaitingList
      || user.role !== 'student',
    [
      tycCheckbox,
      hasUserInscripted,
      isInscriptionClosed,
      hasUserInscriptedInWaitingList,
      isInscriptionOpen,
      user,
    ],
  );

  const disableTyc = () => {
    if (user.role !== 'student') return true;
    return hasUserInscripted
      || isInscriptionClosed
      || hasUserInscriptedInWaitingList
      || !isInscriptionOpen;
  };

  const isFreeCourse = course ? isCourseFree(course) : false;

  const shouldShowVacancies = () => {
    if (user.role !== 'student') return false;
    return !isInscriptionClosed
      && !hasUserInscripted
      && !hasUserInscriptedInWaitingList
      && isInscriptionOpen;
  };

  const shouldShowUnsubscribe = useMemo(
    () => hasUserInscripted || hasUserInscriptedInWaitingList,
    [hasUserInscripted, hasUserInscriptedInWaitingList],
  );

  const textButton = useMemo(() => {
    if (areThereVacanciesFilled) return 'INGRESAR A LISTA DE ESPERA';
    if (course?.preinscription) return 'PREINSCRIPCIÓN';
    return 'INSCRIPCIÓN';
  }, [areThereVacanciesFilled, course?.preinscription]);

  const actionButton = () => {
    if (areThereVacanciesFilled) return enrollCourseToWaitingList;
    if (isFreeCourse) return enrollToCourse;
    return goToNextStep;
  };

  return (
    <Layout>
      <LoadingComponent center loading={!hasComponentLoaded} size={100}>
        <>
          {!isFreeCourse && <InscriptionSteps currentStep={1} />}
          <Grid container columnSpacing={{ md: 10, lg: 12 }} rowSpacing={{ xs: 6 }} direction="row">
            <Grid item xs={12} lg={8}>
              <Box sx={classes.titleContainer}>
                <Typography variant="h1" color="primary.main" sx={classes.pageTitle}>
                  {course?.name}
                </Typography>
                {isFreeCourse && (
                  <Chip
                    label="GRATUITO"
                    size="small"
                    color="success"
                    sx={classes.chipSuccess}
                  />
                )}
              </Box>
              <Typography variant="h5" color="secondary.main" sx={classes.editionText}>
                {course?.edition}
              </Typography>
              <Box sx={classes.scheduleContainer}>
                <Box sx={classes.scheduleGridDateOrTime}>
                  <DateRangeIcon sx={classes.scheduleIcon} />
                  <Typography variant="h6" sx={classes.scheduleTitle}>
                    {`Inicia el ${course?.start_date?.slice(0, 5)}`}
                  </Typography>
                </Box>
                <Box sx={classes.scheduleGridDateOrTime}>
                  <LocationOnIcon sx={classes.scheduleIcon} />
                  <Typography variant="h6" sx={classes.scheduleTitle}>
                    {modalityDisplayDictionary[course?.modality]}
                  </Typography>
                </Box>
              </Box>
              {course && safeParseStringIntoHTML(course.description)}
              <Box sx={classes.tycContainer}>
                <Checkbox checked={tycCheckbox} onClick={() => setTycCheckbox(!tycCheckbox)} size="small" disabled={disableTyc()} />
                <Box sx={classes.infoWithActionLabel}>
                  <Typography variant="h5" color="text.primary" sx={classes.infoWithActionText(disableTyc())}>
                    He leído los
                  </Typography>
                  <Button
                    variant="text"
                    sx={classes.infoWithActionButton}
                    onClick={kindOfOpenModals.tyc}
                    disabled={disableTyc()}
                  >
                    <Typography
                      variant="h5"
                      color="text.primary"
                      sx={classes.infoWithActionButtonText(disableTyc())}
                    >
                      términos y condiciones
                    </Typography>
                  </Button>
                </Box>
              </Box>

              <Grid container sx={classes.buttonContainer}>
                <Grid item>
                  <Button
                    variant="contained"
                    disabled={disableInscription}
                    onClick={actionButton()}
                    fullWidth
                    sx={classes.button}
                  >
                    {textButton}
                  </Button>
                  {shouldShowVacancies() && (
                    <>
                      <Typography
                        variant="subtitle1"
                        align="center"
                        color={areThereVacanciesFilled ? 'error.main' : 'success.main'}
                        sx={classes.buttonNotification}
                      >
                        {areThereVacanciesFilled ? 'No hay vacantes disponibles' : '¡Hay vacantes disponibles!'}
                      </Typography>
                      <Typography variant="h5" align="center" marginTop="4px" color="secondary.main" sx={classes.buttonSecondNotification}>
                        {`Cupo máximo: ${course?.limit_vacancies} vacantes`}
                      </Typography>
                    </>
                  )}
                  {isInscriptionClosed && (
                    <Typography
                      variant="subtitle1"
                      align="center"
                      color="error.main"
                      sx={classes.buttonNotification}
                    >
                      La inscripción a este curso finalizó
                    </Typography>
                  )}
                  {!isInscriptionOpen && (
                    <Typography
                      variant="subtitle2"
                      align="center"
                      width="282px"
                      sx={classes.buttonNotification}
                    >
                      {`La inscripción a este curso comienza el ${course?.start_inscription_date}`}
                    </Typography>
                  )}
                </Grid>
                {shouldShowUnsubscribe && (
                  <Grid
                    item
                    sx={classes.infoWithActionLabelContainer}
                  >
                    <Typography variant="h3" color="primary.main">
                      {hasUserInscriptedInWaitingList ? 'Te encontrás en lista de espera' : 'Ya estás inscripto/a a este curso'}
                    </Typography>
                    <Box sx={classes.infoWithActionLabel}>
                      <Typography variant="h5" color="text.primary" sx={classes.infoWithActionText()}>
                        ¿Querés darte de baja?
                      </Typography>
                      <Button
                        variant="text"
                        sx={classes.infoWithActionButton}
                        onClick={kindOfOpenModals[typeModal]}
                      >
                        <Typography
                          variant="h5"
                          color="error.main"
                          sx={classes.infoWithActionButtonText()}
                        >
                          Clickea aquí
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>

              <Typography variant="h2" color="primary.main" sx={classes.pageSubtitle}>
                Más información
              </Typography>

              { course && <CourseAccordion course={course} />}
            </Grid>
            {otherCourses.length > 0 && (
              <Grid item xs={12} lg={4}>
                <Typography variant="h2" color="primary.main" sx={classes.pageSubtitle}>
                  Otros cursos que podrían interesarte
                </Typography>
                <Box sx={classes.otherCourseContainer}>
                  {otherCourses.map((otherCourse) => (
                    <CourseCard
                      key={otherCourse.name}
                      title={otherCourse.name}
                      modality={otherCourse.modality}
                      date={{ start: otherCourse.start_date, end: otherCourse.end_date }}
                      doAndSendOnClick={() => ({ course: otherCourse })}
                      to={`/courses/${otherCourse.id}`}
                    />
                  ))}
                </Box>
              </Grid>
            )}
          </Grid>
        </>
      </LoadingComponent>
    </Layout>
  );
};

export default React.memo(Course);
