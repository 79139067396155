import { memo } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { LoadingComponentProps } from '../../interfaces/controls';

import classes from './classes';

const LoadingComponent = ({
  center = false,
  children,
  loading,
  size,
}: LoadingComponentProps): JSX.Element => {
  if (loading) {
    return (
      <Box sx={classes.container(center)}>
        <CircularProgress size={size} color="primary" />
      </Box>
    );
  }

  return children;
};

export default memo(LoadingComponent);
