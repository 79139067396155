import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Unstable_Grid2 as Grid, Typography,
} from '@mui/material';
import _ from 'lodash';
import NavbarCourse from '../../components/Navbars/NavbarCourse';
import Layout from '../../components/Layouts/Layout';
import CourseCard from '../../components/CourseCard';
import LoadingComponent from '../../components/Controls/LoadingComponent';
import { formatCourse } from '../../formats/course';
import { useUsers } from '../../contexts/users/context';
import { useAuth } from '../../contexts/auth/context';
import { CoursesFromUserFormattedInterface } from '../../interfaces/user';
import { QueryGetEnrollmentsInterface } from '../../interfaces/enrollment';

const classes = {
  pageTitleContainer: {
    width: '100%',
    textAlign: { xs: 'center', md: 'left' },
  },
  pageTitle: {
    fontSize: '32px',
    letterSpacing: '0.15px',
    lineHeight: '39px',
  },
  coursesGridContainer: {
  },
  coursesGridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  appBar: {
    bgcolor: 'primary.main',
    color: 'background.paper',
    height: '48px',
    minHeight: '48px',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  toolBar: {
    maxHeight: '48px',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  button: {
    height: '100%',
    minWidth: '205px',
    boxShadow: 'none',
    '&:hover': { boxShadow: 'none', opacity: '100%', bgcolor: 'inherit' },
    '&.Mui-disabled': {
      bgcolor: 'inherit', color: 'inherit', borderBottom: '2px solid #E2E5FA', opacity: '100%',
    },
    fontWeight: 400,
    opacity: '70%',
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    textAlign: 'center',
  },
  contentContainer: {
    paddingY: 5,
    paddingX: 7,
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: 3, sm: 6 },
  },
  topContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    gap: { xs: 3, sm: 0 },
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filtersButton: {
    width: '135px',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: '10px',
    gap: 0,
    minWidth: '135px',
  },
  quantityCoursesText: {
    fontWeight: 300,
    lineHeight: '19.36px',
    letterSpacing: '0.15px',
    color: '#828282',
    textAlign: 'right',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25vh',
  },
};

const MyCourses = () => {
  const { state: { auth: user } } = useAuth();
  const { fetchCoursesByUserId } = useUsers();

  const [hasComponentLoaded, setHasComponentLoaded] = useState<boolean>(false);
  const [courses, setCourses] = useState<CoursesFromUserFormattedInterface[]>(undefined);
  const [totalCourses, setTotalCourses] = useState<number>(0);
  const [queryParam, setQueryParam] = useState<Partial<QueryGetEnrollmentsInterface>>({
    limit: 0,
    moreInfo: true,
    state: 'enrolled',
  });

  useEffect(() => {
    (async () => {
      setHasComponentLoaded(false);
      const coursesFetched = await fetchCoursesByUserId(user.id, queryParam);
      if (coursesFetched.courses.length > 0) {
        setTotalCourses(coursesFetched.total);
        const courseFormatted = coursesFetched.courses.map(formatCourse);
        const courseSorted = _.orderBy(courseFormatted, ['orderDateState'], ['asc']);
        setCourses(courseSorted);
      } else {
        setTotalCourses(0);
        setCourses([]);
      }
      setHasComponentLoaded(true);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]);

  const myCoursesNavbar = [
    {
      text: 'MIS CURSOS',
      onClick: () => setQueryParam((prevState) => ({ ...prevState, state: 'enrolled' })),
    },
    {
      text: 'EN LISTA DE ESPERA',
      onClick: () => setQueryParam((prevState) => ({ ...prevState, state: 'wait' })),
    },
  ];

  const quantityCoursesShowing = useMemo(() => `Mostrando ${courses?.length} de ${totalCourses}`, [courses, totalCourses]);

  return (
    <Layout marginContainer={false}>
      <NavbarCourse items={myCoursesNavbar} />
      <LoadingComponent center loading={!hasComponentLoaded} size={100}>
        <Box sx={classes.contentContainer}>
          <Typography
            variant="h3"
            sx={classes.quantityCoursesText}
          >
            {quantityCoursesShowing}
          </Typography>
          <Grid container rowSpacing={4} sx={classes.coursesGridContainer}>
            {courses?.map((course) => (
              <Grid key={course.id} xs={12} md={6} lg={4} xl={3} sx={classes.coursesGridItem}>
                <CourseCard
                  title={course.name}
                  date={{ start: course.start_date, end: course.end_date }}
                  edition={course.edition}
                  modality={course.modality}
                  buttonText="Ir al curso"
                  doAndSendOnClick={() => ({ course })}
                  to={`/courses/${course.id}`}
                  canBeDisabled
                  qualification={course.qualification}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </LoadingComponent>

    </Layout>
  );
};

export default React.memo(MyCourses);
