import {
  Box,
  Grid,
  Button,
  Modal,
  IconButton,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { PendingPaymentsModalProps } from './types';
import classes from './classes';

const PendingPaymentsModal = ({ open, onClick, onCloseModal }: PendingPaymentsModalProps) => (
  <Modal open={open} onClose={onCloseModal}>
    <Box sx={classes.modalContainer}>
      <Box sx={classes.closeButtonContainer}>
        <IconButton size="large" onClick={onCloseModal} sx={classes.closeIconButton}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={classes.modalContentContainer}>
        <Typography
          color="primary.main"
          sx={{
            ...classes.modalTitle,
            fontWeight: 500,
            textAlign: 'center',
          }}
          variant="h3"
        >
          El alumno no cuenta con la totalidad de los pagos aprobados para este curso.
          <br />
          ¿Desea continuar de todos modos?
        </Typography>
        <Grid container sx={classes.modalButtonContainer}>
          <Grid item flexGrow={1}>
            <Button variant="outlined" fullWidth onClick={onClick}>
              Continuar
            </Button>
          </Grid>
          <Grid item flexGrow={1}>
            <Button variant="contained" fullWidth onClick={onCloseModal}>
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Modal>
);

export default PendingPaymentsModal;
