import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import LoadingButton from '../../../../../components/Controls/LoadingButton';

import classes from '../../classes';

interface ModalActionsProps {
  onCancel: () => void;
  onConfirm: () => void;
  label?: string;
  loading?: boolean;
}

const ModalActions = ({
  onCancel,
  onConfirm,
  label = 'Guardar',
  loading = false,
}: ModalActionsProps) => (
  <Grid container sx={classes.modalButtonContainer}>
    <Grid item flexGrow={1}>
      <Button variant="outlined" fullWidth onClick={onCancel}>
        Volver
      </Button>
    </Grid>
    <Grid item flexGrow={1}>
      <LoadingButton
        loading={loading}
        variant="contained"
        fullWidth
        onClick={onConfirm}
      >
        {label}
      </LoadingButton>
    </Grid>
  </Grid>
);

export default ModalActions;
