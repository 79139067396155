import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios, { CancelTokenSource } from 'axios';

import { useEnrollments } from '../../contexts/enrollments/context';
import EditQualificationModal from './components/EditQualificationModal';
import FilterButton from '../../components/Buttons/FilterButton';
import Layout from '../../components/Layouts/Layout';
import TableSearchInput from '../../components/Inputs/TableSearchInput';
import Tooltip from '../../components/Tooltip';
import { buildColumn } from '../../helpers/tables';
import { QUERY_LIMIT, START_PAGE_SIZE } from '../../common/constants';
import { buildRows, getColorChipForQualification, optionsFilters } from './constants';
import { QueryGetEnrollmentsInterface, RowQualificationInterface } from '../../interfaces/enrollment';
import classes from './classes';

const Qualifications = () => {
  const { fetchEnrollments, updateEnrollment } = useEnrollments();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<RowQualificationInterface[]>([]);
  const [queryParam, setQueryParam] = useState<QueryGetEnrollmentsInterface>({
    limit: QUERY_LIMIT,
    moreInfo: true,
    page: START_PAGE_SIZE,
  });
  const [enrollmentSelected, setEnrollmentSelected] = useState<RowQualificationInterface>(null);
  const [hasMoreRows, setHasMoreRows] = useState<boolean>(false);
  const [isEditQualificationModalOpen, setIsEditQualificationModalOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [searchInternalCourseId, setSearchInternalCourseId] = useState<string>('');
  const [totalEnrollments, setTotalEnrollments] = useState<number>(0);
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);
  const isRequestActive = useRef<boolean>(false);

  const columns: GridColDef<RowQualificationInterface>[] = [
    buildColumn({
      field: 'studentName',
      flex: 1,
      headerName: 'Nombre y Apellido',
      minWidth: 280,
    }),
    buildColumn({
      field: 'document',
      flex: 1,
      headerName: 'Tipo y N° de Documento',
      minWidth: 200,
    }),
    buildColumn({
      field: 'internalCourseId',
      headerName: 'ID Interno',
    }),
    buildColumn({
      field: 'courseEnrolled',
      flex: 1,
      headerName: 'Curso al que se inscribió',
      cellAlign: true,
      renderCellSlice: 32,
      minWidth: 250,
    }),
    {
      field: 'qualification',
      flex: 1,
      hideSortIcons: true,
      minWidth: 250,
      renderHeader: () => (
        <Box sx={{
          lineHeight: '16px',
          color: 'primary.main',
          whiteSpace: 'pre-wrap',
          '&:focus': {
            outlineWidth: 0,
          },
          '&:focus-within': {
            outlineWidth: 0,
          },
        }}
        >
          Calificación
        </Box>
      ),
      renderCell: (cell) => (
        <Chip
          label={cell.value}
          size="small"
          color={getColorChipForQualification(cell.value)}
          sx={classes.chip}
        />
      ),
    },
    {
      align: 'right',
      field: 'editQualification',
      flex: 1,
      headerName: '',
      hideSortIcons: true,
      minWidth: 180,
      renderCell: (cell) => (
        <Button
          variant="text"
          sx={classes.seeCourseButton}
          onClick={() => {
            setEnrollmentSelected(cell.row);
            setIsEditQualificationModalOpen(true);
          }}
        >
          {cell.value}
        </Button>
      ),
    }];

  const performEnrollmentFetch = async (query: QueryGetEnrollmentsInterface) => {
    if (cancelTokenRef.current) cancelTokenRef.current.cancel('Operation canceled due to a new request.');

    cancelTokenRef.current = axios.CancelToken.source();
    return fetchEnrollments(query, cancelTokenRef.current.token);
  };

  const getRows = async () => {
    setIsLoading(true);
    isRequestActive.current = true;

    try {
      const enrollmentsDetails = await performEnrollmentFetch(queryParam);

      if (isRequestActive.current && enrollmentsDetails) {
        const { enrollments, total } = enrollmentsDetails;

        setTotalEnrollments(total);

        if (enrollments.length) {
          setHasMoreRows(enrollments.length === QUERY_LIMIT);

          const newEnrollmentsRows = buildRows(enrollments);
          const existingIds = new Set(rows.map((row) => row.id));

          const uniqueEnrollments = newEnrollmentsRows.filter(
            (newRow) => !existingIds.has(newRow.id),
          );

          if (uniqueEnrollments.length) {
            setRows(rows.length ? [...rows, ...uniqueEnrollments] : newEnrollmentsRows);
          }
        }
      }
    } finally {
      if (isRequestActive.current) {
        setIsLoading(false);
        isRequestActive.current = false;
      }
    }
  };

  const setInitValues = (attributesToUpdate?: Partial<QueryGetEnrollmentsInterface>) => {
    isRequestActive.current = false;
    if (cancelTokenRef.current) cancelTokenRef.current.cancel('User initiated new search');
    setRows([]);
    setQueryParam((prevState) => ({
      ...prevState,
      ...attributesToUpdate,
      page: START_PAGE_SIZE,
    }));
  };

  const fetchNextEnrollments = () => {
    if (!isLoading && hasMoreRows) {
      setQueryParam((prevState) => ({ ...prevState, page: prevState.page + 1 }));
    }
  };

  const updateQualification = async (newQualification: string): Promise<void> => {
    const enrollmentUpdated = await updateEnrollment(
      enrollmentSelected.id,
      { qualification: newQualification },
    );
    if (enrollmentUpdated) {
      setIsEditQualificationModalOpen(false);
      setInitValues();
    }
    setEnrollmentSelected(null);
  };

  useEffect(() => {
    getRows();

    return () => {
      cancelTokenRef.current?.cancel('Component unmounted');
      isRequestActive.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]);

  useEffect(() => {
    setInitValues({
      search: search || null,
      internalCourseId: searchInternalCourseId || null,
    });
  }, [search, searchInternalCourseId]);

  return (
    <>
      <Layout marginContainer={false}>
        <Box sx={classes.contentContainer}>
          <Box sx={classes.topContainer}>
            <Typography variant="h1" sx={classes.pageTitle}>
              Calificaciones
            </Typography>
            <Box sx={classes.optionsContainer}>
              <FormControl sx={{ width: '235px' }} variant="outlined">
                <TableSearchInput
                  debounceTime={500}
                  disabled={!!searchInternalCourseId}
                  endAdornment={(<Tooltip title="Buscar por nombre, apellido, DNI o email..." />)}
                  label="Buscar"
                  onSearch={setSearch}
                />
              </FormControl>
              <FormControl sx={{ width: '235px' }} variant="outlined">
                <TableSearchInput
                  debounceTime={500}
                  disabled={!!search}
                  label="Buscar por ID Interno"
                  onSearch={setSearchInternalCourseId}
                />
              </FormControl>
              <FilterButton options={optionsFilters} query={queryParam} setQuery={setInitValues} />
            </Box>
          </Box>
          <Box height="100%" width="100%">
            <InfiniteScroll
              dataLength={rows.length}
              hasMore={hasMoreRows}
              loader={<div />}
              next={fetchNextEnrollments}
              style={{ maxHeight: '100%' }}
            >
              <DataGrid
                autoHeight
                columns={columns}
                disableColumnFilter
                disableColumnMenu
                disableRowSelectionOnClick
                hideFooter
                loading={isLoading}
                localeText={{ noRowsLabel: 'No hay calificaciones' }}
                paginationMode="server"
                rowCount={totalEnrollments}
                rows={rows}
                sx={{ borderWidth: '0px' }}
              />
            </InfiniteScroll>
          </Box>
        </Box>
      </Layout>
      {
        enrollmentSelected && (
          <EditQualificationModal
            enrollment={enrollmentSelected}
            onClickButton={updateQualification}
            onCloseModal={() => {
              setEnrollmentSelected(null);
              setIsEditQualificationModalOpen(false);
            }}
            openModal={isEditQualificationModalOpen}
          />
        )
      }
    </>
  );
};

export default Qualifications;
