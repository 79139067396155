import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface StudentDetailsProps {
  information: string;
  label: string;
}

const StudentDetails = ({ information, label }: StudentDetailsProps) => (
  <Box sx={{ display: 'flex', gap: 0.5 }}>
    <Typography variant="h5" color="secondary.main">
      {`${label}:`}
    </Typography>
    <Typography variant="h5">
      {information}
    </Typography>
  </Box>
);

export default StudentDetails;
