import axios, { CancelToken } from 'axios';

import { axiosErrorHandler, axiosResponseHandler } from './handler';
import {
  DiscountTypes,
  EnrollmentInterface,
  GenericResponseEnrollment,
  QueryGetEnrollmentsInterface,
  ResGetEnrollmentsInterface,
  TypeEnrollment,
  UpdateEnrollmentInterface,
} from '../interfaces/enrollment';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/enrollments`,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

const getAll = async (
  query?: QueryGetEnrollmentsInterface,
  cancelToken?: CancelToken,
): Promise<ResGetEnrollmentsInterface> => instance
  .get('/', { params: query, cancelToken })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const remove = async (enrollmentId: string): Promise<GenericResponseEnrollment> => instance
  .delete(`/${enrollmentId}`)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const update = async (
  enrollmentId: string,
  toUpdate: Partial<UpdateEnrollmentInterface>,
): Promise<GenericResponseEnrollment> => instance
  .put(`/${enrollmentId}`, toUpdate)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const postEnrollment = async (
  userId: string,
  courseId: string,
  typeEnrollment: TypeEnrollment,
  observations: string = '',
  userCategory?: string,
  discount: number = 0,
  discountType: string = DiscountTypes.Percentage,
): Promise<GenericResponseEnrollment> => instance
  .post(`/${typeEnrollment}/`, {
    course: courseId,
    discount,
    discountType,
    observations,
    user: userId,
    userCategory,
  })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const postWaitingList = async (
  userId: string,
  courseId: string,
): Promise<GenericResponseEnrollment> => instance
  .post('/waitingList/', { user: userId, course: courseId })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const getAllCertificates = async (): Promise<EnrollmentInterface[]> => instance
  .get('/certificates')
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const downloadCertificate = async (
  id: string,
): Promise<Blob> => instance
  .post(`/certificates/${id}`, {}, { responseType: 'blob' })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

export default {
  getAll,
  remove,
  postEnrollment,
  postWaitingList,
  update,
  getAllCertificates,
  downloadCertificate,
};
