import { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../../components/Layouts/Layout';
import { useCourses } from '../../contexts/courses/context';
import CourseInterface from '../../interfaces/courses';
import MercadoPagoPayment from '../../components/Payments/MercadoPagoPayment/MercadoPagoPayment';
import LoadingComponent from '../../components/Controls/LoadingComponent';
import { usePayments } from '../../contexts/payments/context';

const Payment = () => {
  const { id: courseId, paymentId } = useParams();
  const navigate = useNavigate();
  const { fetchCourseById } = useCourses();
  const { fetchPaymentById } = usePayments();
  const [loading, setLoading] = useState<boolean>(false);
  const [course, setCourse] = useState<CourseInterface>(null);

  const hasCourseLoaded = (course && Object.keys(course).length !== 0);

  useEffect(() => {
    if (!paymentId || !courseId) navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (!hasCourseLoaded) {
        setLoading(true);
        const courseFetched = await fetchCourseById(courseId);
        setCourse(courseFetched);
        await fetchPaymentById(paymentId);
        setLoading(false);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <LoadingComponent loading={loading} size={80}>
        {hasCourseLoaded && (
        <MercadoPagoPayment
          course={course}
          returnButtonAction={() => navigate('/')}
        />
        )}
      </LoadingComponent>
    </Layout>
  );
};

export default memo(Payment);
