import dayjs from 'dayjs';

import { formatToValidDayjs } from '../utils/date';
import CourseInterface from '../interfaces/courses';

/**
 * It returns a random integer between the min and max values included.
 * @param {number} min - The minimum number that can be returned.
 * @param {number} max - The maximum number in the range.
 */
export const randomIntFromInterval = (min: number, max: number) => Math.floor(
  Math.random() * (max - min + 1) + min,
);

export const FIRST_STEP = 0;
export const SECOND_STEP = 1;
export const THIRD_STEP = 2;

export const modalityDisplayDictionary = {
  mixta: 'Presencial y virtual',
  presencial: 'Presencial',
  virtual: 'Virtual',
};

export const getOtherCourses = (
  courses: CourseInterface[],
  course: CourseInterface,
): CourseInterface[] => {
  const otherCourses = courses.filter(
    ({ id, start_date }) => id !== course.id && dayjs().isBefore(formatToValidDayjs(start_date)),
  );
  const indexRandom = randomIntFromInterval(0, otherCourses.length - 2);
  return otherCourses.splice(indexRandom, 2);
};

export const isCourseFree = (course: CourseInterface) => {
  if (Number(course.preinscription?.amount) > 0) return false;

  if (!course.tariff?.length) return true;

  return course.tariff.every(({ amount }) => Number(amount) === 0);
};
