import axios, { AxiosResponse } from 'axios';

import { ERROR_CANNOT_OPERATE_BETWEEN_DIFFERENT_COUNTRIES } from '../common/errors';

const rejectPromiseWith = (message: string) => Promise.reject(message);

export const axiosResponseHandler = (response: AxiosResponse) => response.data;

export const axiosErrorHandler = (error: unknown) => {
  if (axios.isCancel(error)) return Promise.reject(error);

  if (axios.isAxiosError(error) && error.response) {
    const { response } = error;

    if (response.status === 401) {
      window.location.replace('/logout?sessionExpired');
    }

    if (response.status === 503 || error.response.status === 500) {
      return response.data?.error?.message === ERROR_CANNOT_OPERATE_BETWEEN_DIFFERENT_COUNTRIES
        ? rejectPromiseWith(ERROR_CANNOT_OPERATE_BETWEEN_DIFFERENT_COUNTRIES)
        : rejectPromiseWith('Oops! Un error ha ocurrido.');
    }

    return rejectPromiseWith(error.response.data.error?.message);
  }

  return rejectPromiseWith('Oops! Un error ha ocurrido.');
};
