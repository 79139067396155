const classes = {
  stepsContainer: {
    marginBottom: 4, display: 'flex', justifyContent: { xs: 'center', sm: 'left' },
  },
  scheduleContainer: {
    display: 'flex',
    justifyContent: { xs: 'center', sm: 'start' },
    mb: 0.25,
    gap: { xs: 6, sm: 3 },
  },
  scheduleGridDateOrTime: {
    display: 'flex',
    alignItems: 'center',
  },
  scheduleTitle: {
    lineHeight: '15px',
    letter: '0.15px',
  },
  scheduleIcon: {
    fontSize: '15px',
    mr: 0.5,
  },
  pageTitle: {
    fontSize: '32px',
    letterSpacing: '0.15px',
    lineHeight: '39px',
    fontWeight: 700,
    marginBottom: 0,
    textAlign: { xs: 'center', sm: 'left' },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: { xs: 'center', sm: 'left' },
    alignItems: 'center',
    gap: 2,
  },
  pageSubtitle: {
    fontWeight: 700,
    letter: '0.15px',
    marginBottom: 3,
    textAlign: { xs: 'center', sm: 'left' },
  },
  buttonContainer: {
    marginBottom: 5.5,
    marginTop: 3,
    marginX: { xs: 'auto', sm: '0' },
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    alignItems: { xs: 'center', sm: 'flex-start' },
    gap: { xs: 1, sm: 2 },
  },
  button: { width: '282px' },
  buttonNotification: {
    marginTop: '8px',
    fontSize: '14px',
    fontWeight: 600,
  },
  paginationItem: (disabled: boolean = false) => ({
    fontWeight: 600,
    marginRight: 1.5,
    fontSize: '14px',
    opacity: disabled ? '50%' : '100%',
  }),
  paginationCurrentItem: {
    fontWeight: 600,
    fontSize: '14px',
    marginRight: 1.5,
  },
  editionText: {
    lineHeight: '17px',
    letter: '0.15px',
    marginBottom: 3,
    textAlign: { xs: 'center', sm: 'left' },
  },
  buttonSecondNotification: {
    lineHeight: '17px',
    letter: '0.15px',
    marginBottom: 3,
    textAlign: { xs: 'center' },
  },
  descriptionCourse: {
    opacity: '80%',
    marginTop: 3,
    lineHeight: '17px',
    letter: '0.15px',
    width: { xl: '60%', md: '70%', sm: '80%' },
    textAlign: { xs: 'center', sm: 'left' },
    marginBottom: 1.25,
  },
  tycContainer: {
    display: 'flex',
    justifyContent: { xs: 'center', sm: 'left' },
    alignItems: 'center',
  },
  infoWithActionLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '282px',
  },
  infoWithActionLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoWithActionText: (disable = false) => ({
    marginTop: 0,
    opacity: disable ? '50%' : '100%',
  }),
  infoWithActionButton: {
    padding: 0,
    minWidth: 'auto',
    textTransform: 'none',
    marginLeft: 1,
    height: 'auto',
  },
  infoWithActionButtonText: (disable = false) => ({
    textDecoration: 'underline',
    fontWeight: 600,
    opacity: disable ? '50%' : '100%',
  }),
  otherCourseContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    alignItems: { xs: 'center', sm: 'flex-start' },
  },
  modalContainer: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: 2,
    boxShadow: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    borderRadius: '0px',
    maxWidth: '570px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalTitle: (center = false) => ({
    fontWeight: 600,
    textAlign: center ? 'center' : { xs: 'center', sm: 'left' },
  }),
  modalDescription: (center = false) => ({
    textAlign: center ? 'center' : { xs: 'center', sm: 'left' },
  }),
  modalButtonContainer: {
    marginTop: 1,
    width: '100%',
    gap: { xs: 2, sm: 4 },
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25vh',
  },
  chipSuccess: {
    borderRadius: 0,
    color: 'white',
    letterSpacing: '1.65px',
    paddingX: 0.5,
  },
};

export default classes;
